import React, { FC } from 'react';
import { ButtonCTA } from '../components/common/buttons/Button';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { Divider } from '../components/common/Divider';

const ButtonPage: FC = () => {
  const primary1Classnames = 'white primary-orange-btn mb2';
  const primary1HoverClassname = 'white primary-orange-btn mb2';
  const primary2Classnames = 'ba orange primary-orange2-btn mb2';
  const primary2HoverClassname = 'ba orange5 primary-orange2-btn mb2';

  const secondary1Classnames = 'secondary-blue-btn white mb2';
  const secondary1HoverClassname = 'secondary-blue-btn white mb2';
  const secondary2Classnames = 'ba blue1 secondary-blue2-btn mb2';
  const secondary2HoverClassname = 'ba secondary-blue2-btn blue0 mb2';

  const buttonSizes = [{ width: 'narrow-btn' }, { width: 'wide-btn' }, { width: 'extra-wide-btn' }];

  return (
    <div>
      <Container>
        <Row>
          <h1>Desktop and Mobile</h1>
        </Row>
        <Row>
          <Divider />
        </Row>
        <Row>
          <h2>Primary Buttons</h2>
        </Row>
        <Row>
          <Col sm={2}>Narrow</Col>
          <Col sm={4}>Wide</Col>
          <Col sm={6}>Extra Wide</Col>
        </Row>
        <Row>
          <Col>
            <h4>Default State</h4>
          </Col>
        </Row>
        <Row className="justify-between">
          {buttonSizes.map((button, index) => {
            return (
              <ButtonCTA
                key={index}
                backgroundColor={'orange'}
                className={classNames(primary1Classnames, button.width)}
                bladeContentTypeId={'button-page'}
              >
                Learn more
              </ButtonCTA>
            );
          })}
        </Row>
        <Row>
          <Col>
            <p></p>
          </Col>
        </Row>
        <Row className="justify-between">
          {buttonSizes.map((button, index) => {
            return (
              <ButtonCTA
                key={index}
                backgroundColor={'white'}
                className={classNames(primary2Classnames, button.width)}
                bladeContentTypeId={'button-page'}
              >
                Learn more
              </ButtonCTA>
            );
          })}
        </Row>
        <h4>Hover State</h4>
        <Row className="justify-between">
          {buttonSizes.map((button, index) => {
            return (
              <ButtonCTA
                key={index}
                backgroundColor={'orange5'}
                className={classNames(primary1HoverClassname, button.width)}
                bladeContentTypeId={'button-page'}
              >
                Learn more
              </ButtonCTA>
            );
          })}
        </Row>
        <Row>
          <Col>
            <p></p>
          </Col>
        </Row>
        <Row className="justify-between">
          {buttonSizes.map((button, index) => {
            return (
              <ButtonCTA
                key={index}
                backgroundColor={'white'}
                className={classNames(primary2HoverClassname, button.width)}
                bladeContentTypeId={'button-page'}
              >
                Learn more
              </ButtonCTA>
            );
          })}
        </Row>

        <h2>Secondary Buttons</h2>
        <Row className="justify-between">
          {buttonSizes.map((button, index) => {
            return (
              <ButtonCTA
                key={index}
                backgroundColor={'blue1'}
                className={classNames(secondary1Classnames, button.width)}
                bladeContentTypeId={'button-page'}
              >
                Learn more
              </ButtonCTA>
            );
          })}
        </Row>
        <Row>
          <Col>
            <p></p>
          </Col>
        </Row>
        <Row className="justify-between">
          {buttonSizes.map((button, index) => {
            return (
              <ButtonCTA
                key={index}
                backgroundColor={'white'}
                className={classNames(secondary2Classnames, button.width)}
                bladeContentTypeId={'button-page'}
              >
                Learn more
              </ButtonCTA>
            );
          })}
        </Row>

        <h4>Hover State</h4>
        <Row className="justify-between">
          {buttonSizes.map((button, index) => {
            return (
              <ButtonCTA
                key={index}
                backgroundColor={'blue0'}
                className={classNames(secondary1HoverClassname, button.width)}
                bladeContentTypeId={'button-page'}
              >
                Learn more
              </ButtonCTA>
            );
          })}
        </Row>
        <Row>
          <Col>
            <p></p>
          </Col>
        </Row>
        <Row className="justify-between">
          {buttonSizes.map((button, index) => {
            return (
              <ButtonCTA
                key={index}
                className={classNames(secondary2HoverClassname, button.width)}
                bladeContentTypeId={'button-page'}
              >
                Learn more
              </ButtonCTA>
            );
          })}
        </Row>
        <h2>Tertiary Buttons</h2>
        <Row>
          <Col>Coming Up.....</Col>
        </Row>
      </Container>
    </div>
  );
};

export default ButtonPage;
