import { makeStyledComponent } from '../Elements';
import React from 'react';

export type ButtonUnstyledProps = {
  className: string;
  href?: string;
  buttonStyle?: 'auto' | 'narrow' | 'wide' | 'extra-wide';
  buttonColor?: 'orange' | 'orange-outline' | 'blue' | 'blue-outline';
  onClick?: React.HTMLProps<HTMLAnchorElement>['onClick'];
  openInNewTab?: boolean;
  bladeContentTypeId?: string;
};

const getClassnameFromButtonColor = new Map([
  ['orange', 'bg-orange1 white'],
  ['orange-outline', 'ba b--orange1 orange'],
  ['blue', 'bg-blue1 white'],
  ['blue-outline', 'ba b--blue1 blue1'],
]);

const ButtonUnstyled: React.FC<ButtonUnstyledProps> = ({
  buttonStyle,
  children,
  buttonColor,
  className,
  onClick,
  href,
  openInNewTab,
  bladeContentTypeId,
}) => {
  const buttonType = buttonStyle ? buttonStyle : 'auto';
  const buttonClassNames = `button-cta f2 ls2 fw7 pv2 ph3 tc bw2 ${getClassnameFromButtonColor.get(
    buttonColor || 'orange'
  )} ${buttonType + '-btn'} ${className}`;

  return (
    <a
      onMouseDown={(e) => e.preventDefault()}
      tabIndex={0}
      target={href && openInNewTab ? '_blank' : ''}
      role="button"
      href={href ? href : '#'}
      className={buttonClassNames}
      data-tracking-category={bladeContentTypeId}
      data-tracking-action={'click'}
      data-tracking-label={`sign_up-[${href}]`}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export const ButtonCTA = makeStyledComponent(ButtonUnstyled);
